<!--
 * @Author: yuwenwen
 * @Date: 2022-11-11 08:53:34
 * @LastEditors: Do not edit
 * @LastEditTime: 2022-12-13 18:37:04
 * @FilePath: \nc-funeral-mobile\src\views\mine\myOrder.vue
-->
<template>
  <div class="page-container my-order-page">
    <van-list
      v-model="paginationList.loading"
      :finished="paginationList.finished"
      finished-text="没有更多了"
      @load="onLoad"
      error-text="加载失败,点击重新加载"
    >
      <div
        v-for="(item, index) in paginationList.list"
        :key="index"
        class="my-order-list"
        @click="toOrderDetails(item)"
      >
        <div class="item-header">
          <div>订单时间：{{ item.createTime }}</div>
          <div class="product-tag">{{ item.orderDetails.length }}件商品</div>
        </div>

        <div class="item-content">
          <div class="item" v-for="(rec, i) in item.orderDetails" :key="i">
            <div>{{ rec.productName }}：x{{ rec.salesNum }}</div>
            <div>¥ {{ rec.salesPrice }}</div>
          </div>
        </div>

        <div class="item-footer">
          <div class="total">
            合计：<span>¥ {{ item.totalPrice }}</span>
          </div>
          <!-- 0 未支付、1 已支付 -->
          <div class="pay-status un-pay" v-if="item.payStatus == 0">未支付</div>
          <div class="pay-status" v-if="item.payStatus == 1">已支付</div>
        </div>
      </div>
    </van-list>
  </div>
</template>
<script>
import { getOrderList } from "@/api/product.js";
export default {
  name: "MyOrder",
  data() {
    return {
      orderList: [],
      paginationList: {
        current: 1, //当前页
        size: 10, //一页多少条
        total: 0, //总共多少条
        list: [], //本地list
        loading: false,
        finished: false,
        error: false,
      },
    };
  },
  created() {
    // this.getOrderList();
  },
  mounted() {},
  methods: {
    //获取订单列表
    async getOrderList() {
      let orderList = this.paginationList.list;
      let params = {
        current: this.paginationList.current,
        size: this.paginationList.size,
      };
      let [err, res] = await this.$awaitWrap(getOrderList(params));
      if (err) {
        return;
      }

      let data = res.data;
      if (data && data.records.length > 0) {
        console.log(data.records);
        for (let i = 0; i < data.records.length; i++) {
          let newObj = {
            ...data.records[i],
          };
          orderList.push(newObj);
        }
        this.paginationList.list = orderList;
        this.paginationList.total = data.total;
        this.paginationList.loading = false;
        if (this.paginationList.list.length >= data.total) {
          this.paginationList.finished = true; // 结束加载状态
        }
      } else {
        this.paginationList.finished = true;
      }
    },
    onLoad() {
      let timer = setTimeout(() => {
        if(this.paginationList.total == 0){
          this.getOrderList();
          this.paginationList.current = this.paginationList.current + 1;
          this.paginationList.finished && clearTimeout(timer);
        }else{
          let list = this.paginationList.list;
          let total = this.paginationList.total;
          if(list.length < total){
            this.getOrderList();
            this.paginationList.current = this.paginationList.current + 1;
            this.paginationList.finished && clearTimeout(timer);
          }
        }
      }, 100);
    },

    // 跳转订单详情页面
    toOrderDetails(item) {
      this.$router.push({
        path: "/product/order/details",
        query: { orderNo: item.orderNo },
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../assets/styles/mine.less";
</style>